import { message } from 'antd';
import { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { UpdateSettingsPageData } from 'Redux/Features/DetailSlice';
import { GetSettingsPageData } from 'Redux/Features/DetailSlice';
const PointCalculation = () => {
    const { settingsPagePoiint, loading } = useSelector(state => state.Details)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { point } = data

        dispatch(UpdateSettingsPageData({ point })).then((response) => {


            if (response.type === "data/settingsPageUpdatew/fulfilled") {
                message.success('Ticket Cost updated successfully')
                dispatch(GetSettingsPageData())
            }
            // response.type === "data/settingsPageUpdatew/fulfilled" && message.success('Ticket Cost updated successfully')
        })
    }
    useEffect(() => {
        dispatch(GetSettingsPageData())
        console.log(settingsPagePoiint?.point, 'point');
    }, [])

    return (
        <div>

            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card.Body>
                        <h4>Point Calculation</h4>
                        <Row className="align-items-center">
                            <Col md="3" className="my-1">

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Ticket Cost equivalent to TEN point.</Form.Label>
                                    <Form.Control
                                        {...register("point", {
                                            required: true,
                                            min: {
                                                value: 0.1,
                                                message: 'Point must be greater than 0'
                                            },
                                            max: {
                                                value:1000,
                                                message:`Point can't be greater than 1000`
                                            }
                                        })}
                                        type="number"
                                        step='any'
                                        name='point'
                                        defaultValue={settingsPagePoiint?.point}

                                    />
                                    {errors.point && (
                                        <p style={{ color: 'red' }}>{errors?.point?.message}</p>
                                    )}
                                </Form.Group>
                                {/* <Button className="btn btn-md  m-0" type="submit" variant="success" >Update</Button> */}



                            </Col>
                            <Col md="3" className="my-1">

                                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        
                      <Form.Label>Ticket Cost</Form.Label>
                      
                      <Form.Control type="email" placeholder="2" />
                    </Form.Group> */}
                            </Col>
                            {/* <Col md="2" className="my-1">
                          <h4 className="m-0"><small>Total:</small> 200 </h4>
                        </Col> */}


                            <Col md="3" className="my-1 ml-auto">

                                <Button className="btn btn-md  m-0" type="submit" variant="success" >Update</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </form>
            </Card>

        </div>
    )
}

export default PointCalculation