import React, { useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router';
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Cookies from 'js-cookie';
const Main = () => {
    const history =useHistory()
    const token =Cookies.get('hedge_token')
    useEffect(() => {
        const handleDocumentClick = async() => {
          const cookieValue =await Cookies.get('hedge_token_expiry');
          if (!cookieValue) {
            // Redirect to login page if cookie is not set
            Cookies.remove('hedge_token_expiry')
            history.push('/auth/Login');
          } else {
            // Check if cookie has expired
            const expirationDate = new Date(cookieValue);
           
            const now = new Date();
            
            if (now > expirationDate) {
              // Redirect to login page if cookie has expired
              history.push('/auth/Login');
            }
          }
        };
    
        // Add the click event listener to the document
        document.addEventListener('click', handleDocumentClick);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
      }, [history]);
      console.log(history,'history');
  return (
    <div>
        
        { history?.location?.pathname ==='/' && token && <Redirect from="/" to="/admin/dashboard" />}
        {  !token &&<Redirect from="/" to="/auth/Login" />}
        {  token &&<Redirect from="/auth/Login" to="/admin/dashboard" />}
        {  !token &&<Redirect from="/admin/dashboard" to="/auth/Login" />}
        {/* {  token &&<Redirect from="/admin/dashboard" to="/auth/Login" />} */}
        
                
            
        
        {token ? <Route path="/admin" render={(props) => <AdminLayout {...props} />} />:<Route path="/auth" render={(props) => <AuthLayout {...props} />} />}`
       {/* {token ? <Redirect from="/" to="/admin/dashboard" />:<Redirect from="/" to="/auth/Login" />} */}
    </div>
  )
}

export default Main