
import Dashboard from "views/Dashboard.js";
import Rewards from "views/Rewards.js";
import Settings from "views/Settings.js";
import Users from "views/Users.js";

import PageNotFound from "views/PageNotFound";
import LoginPage from "views/Pages/LoginPage.js";
import RegisteredUserPage from "views/Pages/RegisteredUserPage";
import UsersView from "views/Pages/UsersView";
import RewardsView from "views/RewardsView";
import SportsBook from "views/SportsBook";



var routes = [
  
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    show: true
  },
  {
    path: "/Users/:id",
    layout: "/admin",
    name: "Users",
    icon: "nc-icon nc-circle-09",
    component: Users,
    show: true
  },
  {
    path: "/Login",
    layout: "/auth",
    name: "Login",
    icon: "nc-icon nc-circle-09",
    component: LoginPage,
  },




  {
    path:`/Rewards/:id` ,
    layout: "/admin",
    name: "Scanned Receipts",
    icon: "nc-icon nc-chart-bar-32",
    component: Rewards,
    show: true
  },
  {
    path: `/new-users/:id`,
    layout: "/admin",
    name: "Registered users",
    icon: "nc-icon nc-badge",
    component: RegisteredUserPage,
    show: true
  },
  {
    path: `/sportsbook`,
    layout: "/admin",
    name: "Sportsbook ",
    icon: "nc-icon nc-single-copy-04",
    component: SportsBook,
    show: true
  },



  {
    path: "/Settings",
    layout: "/admin",
    name: "Settings",
    icon: "nc-icon nc-settings-gear-64",
    component: Settings,
    show: true
  },
{
    path: "/reward-view/:id",
    layout: "/admin",
    name: "reward-view",
    icon: "nc-icon nc-chart-pie-35",
    component: RewardsView,

  },
  {
    path: "/user-view/:id",
    layout: "/admin",
    name: "user-view",
    icon: "nc-icon nc-chart-pie-35",
    component: UsersView,

  },
  {
    path: "*",
    layout: "/admin",
    name: "Notfound",
    icon: "nc-icon nc-chart-pie-35",
    component: PageNotFound,
    
  },
  {
    path: "*",
    layout: "/auth",
    name: "Notfound",
    icon: "nc-icon nc-circle-09",
    component: PageNotFound,
  },


  
];
export default routes;
