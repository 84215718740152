import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Input, Select, Table, } from 'antd';
import {
  Button,
  Card, Col,
  // Table,
  Container,
  Form,
  Row
} from "react-bootstrap";
// core components
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Getusers } from "Redux/Features/CounterSlice";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
function ReactTables() {
  const { users, loading } = useSelector(state => state.Counter)
  const history = useHistory()
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const [sort, setSort] = useState({
    name: false,
    email: false,
    joindate: false,
    age: false,
    reg_type: false,
    point: false,
    status: false,
  });
  var item_value = sessionStorage.getItem("item_key");

  // ANTDATA


  const { Search } = Input
  const [basicData, setBasicData] = useState({

    rows: []
  });
  // const [page, setpage] = useState(id == ':id' ? 0 : id - 1)
  const page = id == ':id' ? 0 : id - 1
  const columns = [
    {
      title: '#',
      key: 'index',
      render: (text, record, index, prop) => page * 10 + index + 1   ,
    },
    {
      title: ' Name',
      dataIndex: 'name',
      render: (text, prop) => <a onClick={() => history.push({
        pathname: `/admin/user-view/${prop._id}`,
        state: {
          page: 4
        }
      }


      )}>{text}</a>,
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["full_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["full_name",${sort.name ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              name: !sort.name
            })
          },
        };
      },

      sorter: {

        // compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
      width: '10%',




      // responsive: ["sm"]
    },
    {
      title: 'E-mail',
      dataIndex: 'email',

      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["email",${sort.email ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["email",${sort.email ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              email: !sort.email
            })
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3,
      },
      // responsive: ["sm"]
    },
    {
      title: 'Joining Date',
      dataIndex: 'joindate',
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["created_at",${sort.joindate ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["created_at",${sort.joindate ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              joindate: !sort.joindate
            })
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.joindate.localeCompare(b.joindate),
        multiple: 3,
      },
      // responsive: ["sm"]
    },
    {
      title: 'Age',
      dataIndex: 'Age',
      ellipsis: false,
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["age",${sort.age ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["dob",${sort.age ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              age: !sort.age
            })
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.Age - b.Age,
        multiple: 2,

      },

      responsive: ["sm"]
    },
    {
      title: 'Registration Type',
      dataIndex: 'RegistrationType',
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,

              sort: `[["provider",${sort.reg_type ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["provider",${sort.reg_type ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              reg_type: !sort.reg_type
            })
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.RegistrationType.localeCompare(b.RegistrationType),
        multiple: 1,
      },


      onFilter: (value, record) => record.name.indexOf(value) === 0,
      // width: '10%',
      // responsive: ["sm"]
    },
    {
      title: 'Point',
      dataIndex: 'Point',
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            setParams({
              ...params,
              sort: `[["point",${sort.point ? ` "desc" ` : ` "asc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["point",${sort.point ? ` "desc" ` : ` "asc" `}]]`);
            setSort({
              point: !sort.point
            })
          },
        };
      },
      sorter: {
        // compare: (a, b) => a.Point - b.Point,
        multiple: 1,
      },
      // width: '10%',
      // responsive: ["sm"]
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      onHeaderCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {

            setParams({
              ...params,
              sort: `[["active",${sort.status ? ` "asc" ` : ` "desc" `}]]`,
            })
            sessionStorage.setItem("item_key", `[["active",${sort.status ? ` "asc" ` : ` "desc" `}]]`);
            setSort({
              status: !sort.status
            })

          },
        };
      },
      sorter: {

        // compare: (a, b) => a.Status.localeCompare(b.Status),
        multiple: 1,
      },


      onFilter: (value, record) => record.name.indexOf(value) === 0,

      render(text, record) {
        return {
          props: {
            style: { color: text === 'Blocked' ? "red" : "green" }
          },
          children: <div>{text}</div>
        };
      }

    },

    {
      title: '',
      key: 'operation',
      // fixed: 'right',
      width: 100,
      render: (prop) => <h5 onClick={() => history.push({
        pathname: `/admin/user-view/${prop._id}`,
        state: {
          page: 4
        }
      }


      )} class="text-center">
        <a >
          <h5><i class="fa fa-eye text-primary"></i></h5>
        </a>
      </h5>,
    },

  ];

  // ANTDATA
  const provider = 'provider'
  const active = 'active'
  const Local = "Local"
  const [drpValues, setdrpValues] = useState({
    type: '',
    status: ''
  })
  const [params, setParams] = useState({
    populate: `["location","user","-locations","service","service.service"]`,
    page: 1,
    offset: 0,
    limit: 10,
    sort: `[["created_at", "desc"]]`,
    filter: ``,
    search: '',
    where: { active: true, is_claimed: true },
  });

  const dispatch = useDispatch()
  useEffect(() => {
    sessionStorage.setItem("item_key", `[["created_at", "desc" ]]`);
  }, [])

  // const onFilter =() =>{
  //   console.log(params.filter);
  //   dispatch(Getusers({ offset: 0, params: item_value, search: params.search, filter: params.filter })).then((res) => {

  //     setBasicData({

  //       rows: res?.payload?.users?.map((i) => (
  //         {
  //           key: i.id,
  //           _id: i.id,
  //           name: i.full_name,
  //           email: i.email,
  //           joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
  //           Age: moment().diff(i.dob, 'years'),
  //           Point: i.point == null ? 0 : i.point,
  //           RegistrationType: i.provider === "Local" ? "E-mail " : `${i.provider} `,
  //           Status: i.active ? 'Active' : 'Blocked',

  //         }
  //       ))
  //     })


  //   })
  // }
useEffect(() => {
    console.log(params.filter);
    params.filter&& dispatch(Getusers({ offset: 0, params: item_value, search: params.search, filter: params.filter })).then((res) => {

      setBasicData({

        rows: res?.payload?.users?.map((i) => (
          {
            key: i.id,
            _id: i.id,
            name: i.full_name,
            email: i.email,
            joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
            Age: moment().diff(i.dob, 'years'),
            Point: i.point == null ? 0 : i.point,
            RegistrationType: i.provider === "Local" ? "E-mail " : `${i.provider} `,
            Status: i.active ? 'Active' : 'Blocked',

          }
        ))
      })


    })
  
}, [params.filter])

  useEffect(() => {
    !Cookies.get('hedge_token') && history.push('/auth/Login')
    dispatch(Getusers({ offset: id == ':id' ? 0 : id - 1, params: item_value, search: params.search, filter: params.filter })).then((res) => {

      setBasicData({

        rows: res?.payload?.users?.map((i) => (
          {
            key: i.id,
            _id: i.id,
            name: i.full_name,
            email: i.email,
            joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
            Age: moment().diff(i.dob, 'years'),
            Point: i.point == null ? 0 : i.point,
            RegistrationType: i.provider === "Local" ? "E-mail " : `${i.provider} `,
            Status: i.active ? 'Active' : 'Blocked',

          }
        ))
      })


    })
  }, [params.sort, params.search])




  const onSubmit = async (data) => {

  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title mt-0">
              Users
            </h4>

            <Card className="border-0">
              <Card.Body className="p-0">



                <div className="p-3">
                  <Row className="d-flex justify-content-end mx-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Col lg="4" className="d-flex justify-contenr-center">

                        <div className="mr-4">

                          <Select
                            {...register('status', {

                              // required: 'status is Required',
                              // validate: (value) => !value.trim() == "",
                              // minLength: { value: 6, message: 'Minimum 6 characters required' }
                            })}
                            value={drpValues.status === '' ? null : drpValues.status}
                            style={{ width: 120 }}
                            size="large"
                            name='status,'
                            placeholder='Status'
                            onChange={(value) => {
                              setdrpValues({
                                ...drpValues,
                                status: value
                              })

                              setParams({
                                ...params,
                                filter: [...params.filter, `${JSON.stringify(active)} : ${value}`]
                              },)
                              // setTimeout(() => {
                              //   onFilter()
                              // }, 3000);
                              
                            }}
                            // onChange={handleChange}
                            options={[
                              { value: 'true', label: 'Active' },
                              { value: 'false', label: 'Blocked' },

                            ]}
                          />
                        </div>
                        <div>
                          <Select
                            value={drpValues.type === '' ? null : drpValues.type}
                            style={{ width: 120 }}
                            placeholder='Type'
                            size="large"
                            onChange={(value) => {
                              setdrpValues({
                                ...drpValues,
                                type: value
                              })
                              setParams({
                                ...params,
                                filter: [...params.filter, `${JSON.stringify(provider)} : ${JSON.stringify(value)}`]
                              })
                              history.push(`/admin/Users/:id`)
                            }}
                            options={[
                              { value: 'Apple', label: 'Apple' },
                              { value: "Local", label: 'Email' },
                              { value: 'Facebook', label: 'Facebook' },
                              { value: 'Google', label: 'Google' },


                            ]}
                          />
                        </div>




                        <Button
                          type='reset'
                          onClick={() => {
                            setParams({

                              search: null
                            })
                            setdrpValues({
                              status: '',
                              type: ''
                            })

                            setParams({
                              filter: ''
                            })
                          }}
                          className="ml-3"> Clear
                        </Button>

                      </Col>
                    </form>
                    <Col lg="4">
                      {/* <Form className="d-flex ml-2">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          value={params?.search}
                          className="me-2"
                          aria-label="Search"
                          onChange={(e) => {
                            setParams({

                              search: e.target.value
                            })
                          }}
                        />
                        
                      </Form> */}
                      <Search
                        className="searchBar"
                        placeholder="Search "
                        value={params.search}
                        onChange={(e) => {
                          setParams({
                            ...params,
                            search: e.target.value
                          })
                        }}
                        style={{
                          width: 350,
                          height: 40,
                          marginTop: 9,
                          color: 'black'
                        }}

                      />
                      {/* <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}

                    </Col>

                  </Row>
                </div>







                {/* <div className="container">
                <Input.Search 
                onChange={(input)=>{
                 
                //  alert('hi')
                }}
                />
                </div> */}

                <Table
                  pagination={{
                    showSizeChanger: false,
                    defaultCurrent: id == ':id' ? 1 : parseInt(id),
                    current:id == ':id' ? 1 : parseInt(id),
                    pageSize: 10,
                    total: users.count,
                    onChange: (page,) => {
                      history.push(`/admin/Users/${page}`)
                      // alert('hhhhh')
                      dispatch(Getusers({ offset: page - 1, params: item_value, search: params.search, filter: params.filter })).then((res) => {
                        // (res.payload, 'usr');
                        setBasicData({

                          rows: res?.payload?.users?.map((i) => (
                            {
                              key: i.id,
                              _id: i.id,
                              name: i.full_name,
                              email: i.email,
                              joindate: moment(i.created_at).format("DD MMMM YYYY, h:mm a"),
                              Age: moment().diff(i.dob, 'years'),
                              Point: i.point == null ? 0 : i.point,
                              RegistrationType: i.provider === "Local" ? "E-mail" : `${i.provider} `,
                              Status: i.active ? 'Active' : 'Blocked',

                            }
                          ))
                        })


                      })
                    }
                  }}
                  size="small"
                  loading={loading}
                  columns={columns}
                  dataSource={basicData.rows}



                />


              </Card.Body>
            </Card>
          </Col>


        </Row>



      </Container>
    </>
  );
}

export default ReactTables;