import React, { useEffect, useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";
import { useSelector,useDispatch } from "react-redux";
import { Login } from "Redux/Features/AuthSlice";
import { decrement } from "Redux/Features/CounterSlice";
import { increment } from "Redux/Features/CounterSlice";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router";
import { message, Spin } from "antd";
import Cookies from "js-cookie";
import logo2 from '../../assets/img/logo2.png'
function LoginPage() {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [loading, setloading] = useState(false);
  const {value}=useSelector(state=>state.Counter)
  const dispatch =useDispatch()
  const history = useHistory()
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
const token=Cookies.get('hedge_token')

  const onSubmit = async (data) => {
    setloading(true)
    dispatch(Login({data})).then((response)=>{
      
      if (response.type==="auth/login/fulfilled") {
        message.success("Login Success")
        reset()
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));
        Cookies.set('hedge_token', response.payload.data.data.token,{ expires: expiryDate })
        Cookies.set('hedge_token_expiry', expiryDate,{ expires: expiryDate })
        Cookies.set('hedge_session_id', response.payload.data.data.session_id,{ expires: expiryDate })
         window.location.href = "/admin/dashboard"
        setloading(false)

      } else {
        message.error("Invalid Credentials")
        setloading(false)
      }
    })
    
  }
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  useEffect(() => {
    if (token) {
      
      history.push('/admin/dashboard')
    }
  }, [token])
  
  
  return (
    <>
      <div
        className="full-page "
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="6" md="8">
            <Form onSubmit={handleSubmit(onSubmit)} action="" className="form" method="">
                <Card className={"card-login " + cardClasses}>
                  <Card.Header className="text-center pt-4">
                    <img
                      alt="..."
                      src={logo2}
                    ></img>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Email address</label>
                        <Form.Control
                          {...register('email',
                            {
                              required: 'Email address is required',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i, message: 'Invalid E-mail Address',

                              }
                            }

                          )}
                          placeholder="Enter email"
                          type="email"
                          name="email"
                        ></Form.Control>
                        {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}

                      </Form.Group>
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          {...register('password',
                            {
                              required: 'Password is required',
                              minLength: { value: 6, message: 'Password must be minimum 6 characters' }
                            })}
                          name='password'
                          placeholder="Enter Password"
                          type="password"
                        ></Form.Control>
                        {errors?.password && <p style={{ color: 'red' }}>{errors?.password.message}</p>}

                      </Form.Group>
                      <Spin spinning={loading}>
                      <Button className="btn-lg w-100" type="submit" variant="warning">
                        Login

                      </Button>
                      </Spin>
                    </Card.Body>
                  </Card.Body>

                </Card>
              </Form>
              
              {/* <button onClick={()=> dispatch(Login({item:1})) }>add</button>
              <button onClick={()=> dispatch(decrement()) }>Minus</button> */}
            </Col>
          </Container>
        </div>
   
      </div>
    </>
  );
}

export default LoginPage;